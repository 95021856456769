(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  const Misc = {

    isRwdSize($size) {
      return $(`.c-rwd-size--${$size}`).is(':visible');
    },

    nbsp() {
      $('p').each((i, el) => {
        const $this = $(el);

        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0) {
          $this.css('margin-bottom', 10);
        }
      });
    },

    pageTitleWhenSidebar() {
      const title = $('.c-page__title');
      const sidebar = $('.c-sidebar, .c-blocks__sidebar');

      //sidebar.css('opacity', 0);

      if( !title.length || !sidebar.length ) {
        return;
      }

      const titleClass = sidebar.hasClass('c-sidebar--3') ? 'o-layout__item--medium-9' : 'o-layout__item--medium-8';

      title
        .addClass('c-page__title--pl')
        .parent()
        .removeClass('o-layout__item--full')
        .addClass(`o-layout__item--small-12 ${titleClass}`)
        .parent()
        .css({
          'justify-content': sidebar.hasClass('c-sidebar--left') || sidebar.hasClass('c-blocks__sidebar--left') ? 'flex-end' : 'flex-start',
        });
    },

    sidebarPosition() {
      const sidebar = $('.c-sidebar, .c-blocks__sidebar');
      const title = $('.c-page__title');

      if( title.length ) {
        title.css({
          'opacity': 1
        });
      }

      if( !sidebar.length ) {
        return;
      }

      let marginTop = 0;
      const outerDiv = sidebar.closest('.c-page-content').length ? sidebar.closest('.c-page-content') : sidebar.parent();

      if( title.length ) {
        marginTop = title.parent().parent().outerHeight() + parseInt( outerDiv.css('padding-top') );
      }else {
        marginTop = parseInt( sidebar.parent().css('padding-top') );
      }

      if(this.isRwdSize('large')) {
        marginTop = 0;
      }

      sidebar
        .css({
          'margin-top': -marginTop,
          'opacity': 1
        });
    },

    memberGrayScale() {
      const memberImage = $('.c-member__image');

      if (memberImage.length) {
        memberImage.find('img').addClass('grayscale grayscale-fade');
      }
    },

    sliderFullHeightCaptionDotsPosition() {

    },

    init() {
      $(document).ready( () => {
        this.nbsp();
        this.pageTitleWhenSidebar();
      });

      $(window).on('load', () => {
        this.sidebarPosition();
        this.memberGrayScale();
      });

      $(window).on('resize', () => {
        this.sidebarPosition();
      });
    }

  }

  module.exports = Misc;

})(jQuery, window, document);
