(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  class SubmitForms {

    constructor() {
      this.form = $('.c-sf__form');

      if (!this.form.length) {
        return;
      }

      this.dateFields = this.form.find('.c-form__date-field, .c-form__datetime-field');
      this.formInner = this.form.find('.c-sf__form-inner');
      this.msgs = this.form.find('.c-sf__msg');
      this.success = this.form.find('.c-sf__msg--success strong');
      this.loader = this.form.find('.c-sf__loader');
      this.loading = false;
      this.submitAnotherBtn = this.form.find('.c-sf__form-submit-another');

      this.submitFormsDatepicker();

      this.submitForm();
    }

    submitFormsDatepicker() {
      if (!this.dateFields) {
        return;
      }

      $.each(this.dateFields, (i, el) => {
        const dateField = $(el),
          hasTime = dateField.hasClass('c-form__datetime-field');

        dateField.flatpickr({
          enableTime: hasTime,
          time_24hr: false,
          onOpen: (selecetdDate, dateStr, instance) => {
            const calendar = $(instance.calendarContainer);

            if( hasTime ) {
              calendar.addClass('showTimeInput');
            }
          }
        });
      });
    }

    submitForm() {
      this.form.on('submit', (e) => {
        e.preventDefault();

        if (this.loading) {
          return;
        }

        this.loading = true;
        this.loader.css('display', 'block');
        this.msgs.text('').removeClass('c-sf__msg--success').removeClass('c-sf__msg--error');
        this.submitAnotherBtn.removeClass('show');

        const form = $(e.target);

        const formType = form.attr('class').split('--').pop();

        if (formType !== 'event') {
          return;
        }

        const formData = new FormData(e.target);

        const checkboxesRadios = $(form).find('input[type=checkbox], input[type=radio]')

        $.each(checkboxesRadios, (i, field) => {
          let formField = $(field),
            name = formField.attr('name');

          if( !formField.is(':checked') ) {
            formData.append(name, []);
          }
        });

        formData.append('action', 'x5_submit_forms');
        formData.append('form_type', formType);

        $.ajax({
          url: x5_scripts .ajaxurl,
          type: 'post',
          contentType: false,
          processData: false,
          data: formData,
          success: response => {
            this.loader.hide();
            switch(response[0]) { // eslint-disable-line no-magic-numbers
            case 'ok':
              this.formInner.fadeOut(300, () => {
                this.msgs.text(response[2]).addClass('c-sf__msg--success');
                this.submitAnotherBtn.addClass('show');
              });
              break;
            case 'error':
              this.loading = false;
              this.msgs.text(response[2]).addClass('c-sf__msg--error');
              break;
            }
          },
          error: response => {
            console.log(response)
          }
        });
      })

    }

  }

  const SubmitFormsInit = {
    init() {
      $(window).on('load', () => {
        new SubmitForms();
      });
    }
  }

  module.exports = SubmitFormsInit;

})(jQuery, window, document);
