/*
  Project: Press Plus
  Author: Xfive
 */

const Misc = require('./misc.js');
const SubmitForms = require('./submit-forms.js');

Misc.init();
SubmitForms.init();
